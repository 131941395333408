footer {
  .copyright {
    font-size: 12px;
    line-height: 16px;
  }

  .nav-link {
    font-size: 14px;
    font-weight: 500;
  }
}

main {
  padding-bottom: 48px;

  &.public-layout {
    padding-top: 80px;
    height: 100%;
  }
}
