.c-form-input {
  position: relative;
  .c-form-input__icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    font-size: 24px;
    color: #2721ae;
    cursor: pointer;
  }
}
