.contact-us-form {
  label.form-label {
    color: #515468;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'salt' on;
    /* .Inputs/.Label */
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px; /* 100% */
    text-transform: uppercase;
  }
}
