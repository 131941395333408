.step-wrapper {
  display: flex;
  flex-direction: column;

  .step-name {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
    line-height: 16px;
    margin-top: 4px;
    color: #04137d;
  }
}

.indexedStep {
  color: #2721ae;
  width: 24px;
  height: 24px;
  font-size: 14px;
  font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;
  font-weight: 500;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #ffffff;
  border: 2px solid #2721ae;
  cursor: pointer;
}

.indexedStep.accomplished {
  background-color: #2721ae;
  color: white;
  border-style: none;
}

.RSPBprogressBar {
  height: 2px;
  width: min(1100px, 95%);
  line-height: 1;
  border-radius: 10px;
  position: relative;
  background-color: #9bb0c9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
  margin: 12px auto 32px;
}

.RSPBprogressBar .RSPBstep {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: absolute;
  transform: translateX(-50%);
  transition-property: all;
  transition-timing-function: ease;
}

.RSPBprogressBar .RSPBprogressBarText {
  color: white;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.RSPBprogressBar .RSPBprogression {
  position: absolute;
  transition: width 0.3s ease;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  background: #2721ae;
  z-index: -1;
}

.RSPBprogressBar > .RSPBstep > .step-wrapper.accomplished > .indexedStep {
  background-color: #2721ae;
  color: white;
  border-style: none;
}

.RSPBprogressBar > .RSPBstep > .step-wrapper.current > .step-name {
  font-weight: 700;
}

.RSPBprogressBar > .RSPBstep > .step-wrapper.current > .indexedStep {
  background-color: #f0f7fe;
  border-style: solid;
  color: #2721ae;
}

.RSPBprogressBar > .RSPBstep > .step-wrapper.future > .indexedStep {
  border-color: #9bb0c9;
  color: #9bb0c9;
  background-color: #f0f7fe;
}

@media screen and (max-width: 480px) {
  .indexedStep {
    width: 15px;
    height: 15px;
    font-size: 6px;
  }
}
