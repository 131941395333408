#flatfile-view {
  iframe {
    width: 100%;
    height: 100%;
  }

  .flatfile_iframe-wrapper {
    background: none !important;
  }

  .flatfile_iframe-wrapper {
    position: relative !important;
  }
}
