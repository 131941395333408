$enable-validation-icons: false;

@import 'bootstrap/scss/bootstrap.scss';
@import 'react-step-progress-bar/styles.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

:root {
  --bs-dark-rgb: 81, 84, 104;
  --bs-body-color: #010417;
  --bs-body-color-rgb: 1, 4, 23;
  --bs-light-rgb: 221, 227, 246;
  --bs-body-bg-rgb: 240, 247, 254;
  --bs-body-bg: #f0f7fe;
  --bs-primary-rgb: 39, 33, 174;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2721ae;
  --bs-btn-border-color: #2721ae;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2721ae;
  --bs-btn-hover-border-color: #2721ae;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2721ae;
  --bs-btn-active-border-color: #2721ae;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2721ae;
  --bs-btn-disabled-border-color: #2721ae;
}

.btn-outline-primary {
  --bs-btn-color: #2721ae;
  --bs-btn-border-color: #2721ae;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2721ae;
  --bs-btn-hover-border-color: #2721ae;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2721ae;
  --bs-btn-active-border-color: #2721ae;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2721ae;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2721ae;
  --bs-gradient: none;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #11c14d;
  --bs-btn-border-color: #11c14d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #13b94a;
  --bs-btn-hover-border-color: #13b94a;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #13b94a;
  --bs-btn-active-border-color: #13b94a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #11c14d;
  --bs-btn-disabled-border-color: #11c14d;
}

.btn-outline-secondary {
  --bs-btn-color: #11c14d;
  --bs-btn-border-color: #11c14d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #11c14d;
  --bs-btn-hover-border-color: #13b94a;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #13b94a;
  --bs-btn-active-border-color: #13b94a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #13b94a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #11c14d;
  --bs-gradient: none;
}

.btn {
  --bs-btn-padding-y: 0.5rem;
  border-radius: 20px;
  line-height: 26px;
  font-weight: 700;

  &:disabled {
    background-color: #e8e8ed;
    color: #c4c5d1;
  }
}

.btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 1rem;
}

.btn-lg {
  --bs-btn-padding-y: 0.875rem;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 1.25rem;
}

h1 {
  font-size: 36px;
  line-height: 1.25;
  font-weight: 900;
}

h2 {
  font-size: 24px;
  line-height: 1.25;
  font-weight: 900;
}

h3 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 800;
}

p,
.paragraph {
  font-size: 14px;
  line-height: 24px;
}

.page {
  height: 100%;
}

.inpage {
  padding: 40px 0;
}

.no-style-link {
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
}

.form-control,
.form-select {
  border: 1px solid #dde3f6;
  border-radius: 12px;
  height: 40px;
  padding: 8px;
  margin-bottom: 0.25rem;
}

.flatfile-close-button {
  display: none;
}

html,
#root {
  // height: 100%;
  min-height: 100%;
}
body {
  height: 100%;
}
