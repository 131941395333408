.result-show {
  text-align: center;
  margin-top: 48px;
  .result-checked {
    svg.alert-yellow {
      width: 80px;
      height: 80px;
      color: #ffb800;
    }
    h1 {
      font-weight: 900;
      font-size: 36px;
      line-height: 48px;
      color: #2721ae;
      margin-bottom: 0;
    }
    .alert_detail {
      margin: 24px auto 40px auto;
      width: 660px;
      h3 {
        margin-bottom: 24px;
      }
    }
    .pay-section {
      display: flex;
      margin: 0 auto 0 auto;
      justify-content: center;
      align-items: center;
      gap: 48px;
      height: 72px;
      width: fit-content;
      padding: 16px;
      border: 1px solid #dde3f6;
      border-radius: 9999px;
      button {
        background: #2721ae;
        border-color: #2721ae;
        padding: 8px 24px;
        height: 40px;
      }
    }
    .download-result {
      padding: 10px 24px;
      height: 44px;
      background: #11c14d;
      box-shadow: 0 2px 4px rgb(31 31 35 / 10%);
      border-radius: 100px;
      margin: 40px 0;

      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .add-label {
    margin: 0 auto 40px auto;
    width: 480px;
    height: 72px;
    text-align: left;
    .add-label__form {
      display: flex;
      gap: 16px;
      margin-bottom: 16px;
      .add-label__form__input {
        flex-grow: 1;
        height: 40px;
        background: white;
        border: 1px solid #e8e8ed;
        border-radius: 12px;
        padding: 8px 12px;
        outline: none;
        font-size: 14px;
        font-weight: 500;
        color: #010417b3;
      }
      .add-label_actions {
        display: flex;
        align-items: self-start;
        margin-top: 2px;
        gap: 8px;
        .add_label_button {
          background-color: transparent;
          width: 40px;
          height: 40px;
          outline: none;
          border: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            font-size: 24px;
          }

          svg.close {
            color: #8593c2;
          }

          svg.check {
            color: #2721ae;
          }
        }
      }
    }
  }
}
.result-accuracy-show {
  .added_lists {
    .list-title {
      display: flex;
      gap: 12px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    .list-line {
      display: flex;
      gap: 24px;
      margin-bottom: 24px;
      .list {
        position: relative;
        padding: 24px;
        background: #ffffff;
        box-shadow: 0 2px 4px rgb(31 31 35 / 10%);
        border-radius: 12px;
        flex: 1;
        text-align: center;
        p {
          font-weight: 700;
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
}
.detail_features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  p {
    margin-top: 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  .button_group {
    padding: 0;
    .go_dashboard {
      margin-left: 16px;
      background: transparent;
      color: #2721ae;
    }
  }
}
