.gradient-background {
  background: linear-gradient(
    74deg,
    color-mix(in srgb, #a7a4ee, #352ed8),
    #352ed8,
    #4052f0,
    #04137d,
    #4052f0,
    #352ed8,
    color-mix(in srgb, #a7a4ee, #352ed8)
  );
  animation: gradient-animation 12s linear infinite;
  background-size: 300%;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 300% 50%;
  }
  // 100% {
  //   background-position: 0% 50%;
  // }
}
