.history-list {
  background: #ffffff;
  padding: 24px;
  border-radius: 24px;
  table {
    width: 100%;

    tbody tr:nth-child(odd) {
      background-color: #f0f7fe;
      border-radius: 8px;
    }

    tbody tr:hover {
      background-color: #dde3f6;
    }

    tbody tr {
      transition: background-color 50ms linear;
    }

    th,
    td {
      padding: 8px 16px;
    }

    td {
      font-size: 14px;
    }
  }
}

.history-section {
  height: 500px;
}
