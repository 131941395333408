.dashboard-page {
  .main-section {
    .section-title {
      margin-bottom: 16px;
    }

    .main-flow {
      margin-top: 32px;
      margin-bottom: 40px;
    }

    .step-card {
      position: relative;
      width: 318px;

      &-box {
        width: 100%;
        padding: 16px;
        border-radius: 24px;
        height: 180px;
        border: 2px solid #dde3f6;
        display: flex;
        align-items: center;
        flex-flow: column;

        h3 {
          align-self: flex-start;
          letter-spacing: -0.3px;
        }
      }

      .card-order {
        position: absolute;
        top: -16px;
        left: -16px;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-color: #55575e;
        text-align: center;
        color: white;
        line-height: 40px;
        margin-bottom: 16px;
      }
    }
  }
}
