.form-check {
  padding-left: 32px;
}

.form-check-input {
  border-radius: 4px;
  width: 24px;
  height: 24px;
  margin-top: 0;
}

.form-check label {
  margin-left: 8px;
}

.form-check-input:checked {
  background-color: #2721ae;
  border-color: #2721ae;
}
